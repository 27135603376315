<template>
	<page-container :page="page">
		<component :is="resolveComponent(page.pageID)" :page="page" />
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { locale } = useI18n();

await fetchPage();

const { data: hotels } = await useWebsiteFetch('hotels', {
	query: { language: locale.value },
	key: `${locale.value}/hotels`,
});

const hotelIds = hotels.value.map((hotel) => hotel.pageID);

const resolveComponent = (pageID) => {
	if (hotelIds.includes(pageID)) {
		return Hotel;
	}

	return Page;
};

const Page = resolveComponent('Page');
const Hotel = resolveComponent('Hotel');
</script>
