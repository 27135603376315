<template>
	<heading-image
		v-if="page.headingImage"
		:heading-image="{
			image: page.headingImage,
			imageWebp: page.headingImageWebp,
			imagePortrait: page.headingImagePortrait,
			imagePortraitWebp: page.headingImagePortraitWebp,
		}"
	/>

	<hotel-navigation
		v-if="page.pageHotel"
		:hotel="page.pageHotel"
		:home="`/${page.pageHotel?.filename}`"
		:class="page.headingImage ? '' : 'no-slider'"
	/>

	<main-content
		:class="{ noslider: !page.headingImage }"
		:title="page.title || page.header"
		:subtitle="page.subtitle"
		:content="page.content"
	>
		<template #content>
			<div class="columns column7 align-left">
				<div class="title-reverse-wrapper">
					<h1>{{ page.title || page.header }}</h1>
					<h4 v-if="page.subtitle" class="subtitle">
						{{ page.subtitle }}
					</h4>
				</div>
				<div v-parse-links v-html="page.content" />
			</div>
		</template>
	</main-content>

	<contentblocks
		v-if="page.contentblocks && page.contentblocks.length"
		:title="page.sectionContentblocksTitle"
		:content="page.sectionContentblocksContent"
		:items="page.contentblocks"
	/>

	<review-slider v-if="page.showReviewSlider" :header="defaults[locale].website.reviewsHeader" />
	<frequently-asked-questions
		v-if="page.frequentlyAskedQuestion"
		:header="page.frequentlyAskedQuestion.header"
		:items="page.frequentlyAskedQuestion.faqitems"
	/>
	<pre-footer v-if="page.footerSection && page.footerSection.length" :footer-section="page.footerSection[0]" />
</template>

<script setup>
defineProps({
	page: {
		type: Object,
		required: true,
	},
});

const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchDefaults();
</script>
