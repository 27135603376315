<template>
	<heading-image
		v-if="page.headingImage"
		:heading-image="{
			image: page.headingImage,
			imageWebp: page.headingImageWebp,
			imagePortrait: page.headingImagePortrait,
			imagePortraitWebp: page.headingImagePortraitWebp,
		}"
	/>

	<hotel-navigation :hotel="page" :home="page.filename" />

	<main-content :title="page.title || page.header" :subtitle="page.subtitle" :content="page.content">
		<template #content>
			<div class="columns column7 align-left">
				<div class="title-reverse-wrapper">
					<h1>{{ page.title || page.header }}</h1>
					<h4 v-if="page.subtitle" class="subtitle">
						{{ page.subtitle }}
					</h4>
				</div>
				<div v-parse-links v-html="page.content" />
			</div>
			<div class="columns column5 contact-info">
				<ul>
					<li>
						<font-awesome-icon icon="fal fa-location-dot" size="1x" />
						<span>
							{{ page.street }} {{ page.number }}, {{ page.postalCode }} {{ page.city }},
							{{ page.country }}
						</span>
					</li>
					<li>
						<font-awesome-icon icon="fal fa-phone" size="1x" />
						<span>
							<a :href="`tel:${$phoneRaw(page.phone)}`">{{ page.phone }}</a>
						</span>
					</li>
					<li>
						<font-awesome-icon icon="fal fa-envelope" size="1x" />
						<span>
							<a :href="`mailto:${page.email}`">{{ page.email }}</a>
						</span>
					</li>
				</ul>
			</div>
		</template>
	</main-content>

	<booking-form-hotel :booking-form="defaults[locale].website" :hotel-id="page.bookingID" />

	<section class="main-content background-color rooms-items">
		<div v-if="page.sectionRoomsTitle" class="blocks-header row align-center">
			<div class="columns column12">
				<div class="pre-header-logo grey" />
				<h2>{{ page.sectionRoomsTitle }}</h2>
				<div v-parse-links v-html="page.sectionRoomsContent" />
			</div>
		</div>

		<div v-for="room in roomsData" :key="room.pageID" class="content-block row">
			<div class="columns column4">
				<div class="contentblock-content">
					<h3>{{ room.header }}</h3>
					<div v-html="room.content"></div>
					<book-button class="button button-cta" :roomid="room.bookingID">
						{{ $t('bookThisRoom') }}
					</book-button>
				</div>
			</div>
			<picture>
				<source v-if="room.imageWebp" :srcset="room.imageWebp" type="image/webp" />
				<source :srcset="room.image" />
				<img :src="room.image" :alt="room.imageAlt" class="room-item-img" loading="lazy" />
			</picture>
		</div>
	</section>

	<contentblocks
		v-if="page.contentblocks && page.contentblocks.length"
		:title="page.sectionContentblocksTitle"
		:content="page.sectionContentblocksContent"
		:items="page.contentblocks"
	/>

	<review-slider v-if="page.showReviewSlider" :header="defaults[locale].website.reviewsHeader" />
	<frequently-asked-questions
		v-if="page.frequentlyAskedQuestion"
		:header="page.frequentlyAskedQuestion.header"
		:items="page.frequentlyAskedQuestion.faqitems"
	/>
	<pre-footer v-if="page.footerSection && page.footerSection.length" :footer-section="page.footerSection[0]" />
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const { data: roomsData } = await useWebsiteFetch('rooms', {
	query: { hotelID: page.value.pageID, language: locale.value },
	key: `${locale.value}/rooms/${page.value.pageID}`,
});
</script>

<style lang="scss" scoped>
.contact-info {
	background: #000;
	padding: 80px 60px;

	ul {
		list-style: none;
	}

	li {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space;

		span {
			font-size: calc(var(--body-font-size) - 1px);
			color: #fff;
		}

		a {
			color: #fff;
		}

		svg {
			color: var(--cta-color);
			margin: 0 12px 0 0;
		}
	}
}

.content-block {
	padding: 30px 50px;
	margin: 40px auto;
	position: relative;
	display: flex;
	flex-flow: row wrap;

	img {
		width: calc(100% - 40px);
		height: 100%;
		position: absolute;
		inset: 0 20px;
		z-index: 1;
		object-fit: cover;
	}

	.columns {
		z-index: 3;
		position: relative;
		background: rgba(255 255 255 / 40%);
		backdrop-filter: blur(34px);
		padding: 0;

		p,
		:deep(p),
		h3,
		:deep(h3) {
			color: var(--header-color);

			a {
				color: var(--header-color);
			}
		}

		:deep(.no-fill),
		.no-fill {
			color: #fff;
			border-color: #fff;

			&:hover {
				color: #fff;
				background: #000;
				border-color: #000;
			}
		}
	}

	.contentblock-content {
		padding: 40px;
	}
}

.blocks-header {
	h2 {
		color: #fff;
	}

	p,
	:deep(p) {
		color: var(--body-color);
	}
}

@media (max-width: 900px) {
	.content-block {
		margin: 0 auto 30px;

		.columns {
			width: 75%;
		}

		.contentblock-content {
			padding: 30px;
		}
	}

	.contact-info {
		padding: 60px 40px;
		width: 100%;
		max-width: 600px;
		margin: 20px auto 0;
	}
}

@media (max-width: 600px) {
	.content-block {
		padding: 40px 20px;

		img {
			inset: 0 10px;
			width: calc(100% - 20px);
		}

		.columns {
			width: 88%;
			background: rgba(255 255 255 / 50%);
			backdrop-filter: none;
		}
	}

	.contact-info {
		padding: 40px 20px;
	}
}

@media (min-width: 980px) {
	.intro .column7 {
		padding-right: 100px;
	}
}
</style>
